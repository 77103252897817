import domReady from '@roots/sage/client/dom-ready';

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...

  function toggleActiveClass(event) {

    const highlightedHamburger = document.querySelectorAll('.hamburger');
    const highlightedToggle = document.querySelectorAll('.menu-toggle-content');
    
    highlightedHamburger.forEach((burgerItem) => {
      burgerItem.classList.toggle('is-active');
    });
    highlightedToggle.forEach((toggleItem) => {
      toggleItem.classList.toggle('is-active');
    });
  }

  const highlightedMenu = document.querySelectorAll('.menu-toggle');

  highlightedMenu.forEach((menuItem) => {
    menuItem.addEventListener('click', toggleActiveClass);
  });

  const highlightedOverlay = document.querySelectorAll('.nav-primary-overlay');

  highlightedOverlay.forEach((overlayItem) => {
    overlayItem.addEventListener('click', toggleActiveClass);
  });

});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);

// Import Bootstrap
import 'bootstrap';

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight, faStar, faAngleDown } from '@fortawesome/free-solid-svg-icons';
library.add(faInstagram, faFacebookF, faTwitter, faLinkedinIn, faArrowRight, faStar, faAngleDown);
dom.watch();